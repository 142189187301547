<template>
  <div class="content-wrapper">
    <div class="orders main-content">

      <div class="page-header">
        <h1>Orders</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Reference ID" @search="searchOrder" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Company">
                <el-input v-model="searchForm.company"></el-input>
              </el-form-item>
              <el-form-item label="Customer Name">
                <el-input v-model="searchForm.email_address"></el-input>
              </el-form-item>
              <el-form-item label="Status">
                <el-select v-model="searchForm.status" placeholder="Please select status">
                  <el-option
                    v-for="item in statusOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>
        <div class="tool">
          <router-link :to="{name:'AddOrder'}">
            <button class="btn btn-primary">
              <i class="el-icon-plus"></i>
              New
            </button>
          </router-link>
        </div>
      </div>

      <div class="orders-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Reference Id</th>
              <th scope="col">Customer Name</th>
              <th scope="col">Status</th>
              <th scope="col">Amount</th>
              <th scope="col">Create Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders">
              <td>{{order.reference_id}}</td>
              <td>{{order.customer_name}}</td>
              <td>{{order.status}}</td>
              <td>${{getTotalAmount(order)}}</td>
              <td>{{ formatDateTime(order.create_date, 'YYYY-MM-DD HH:mm:ss') }}</td>
              <td class="action">
                <router-link :to="{name:'Order', params: {id: order.id}}">View</router-link>
                <a href="#" class="delete-link" @click="deleteOrder(order.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
// @ is an alias to /src
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Sales from '@/lib/sales';

export default {
  name: 'Orders',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {
        reference_id: '',
        customer_name: '',
        status: '',
      },
      statusOptions:[
        {'label': 'Pending', 'value': 'Pending'},
        {'label': 'Processing', 'value': 'Processing'},
        {'label': 'Canceled', 'value': 'Canceled'},
        {'label': 'Processed', 'value': 'Processed'}
      ],
      originOrders: [],
      orders: [],
    }
  },
  mounted(){
    this.loadOrders();
  },
  methods:{
    async loadOrders(){
      const loginInfo = Common.getLoginInfo();
      try{
        const orders = await Sales.loadAllOrders(this.apiUrl, loginInfo);
        this.originOrders = orders;
        this.orders = orders;
      }catch(err){
        console.log(err);
      }
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.orders = this.originOrders;
    },
    searchOrder(textSearchValue){
      this.searchForm['reference_id'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newOrders = Common.filterItems(searchFilter, this.originOrders);
      this.orders = newOrders;
    },
    getTotalAmount(order){
      let totalAmount = 0;
      const orderItems = order.order_items;
      for(let i = 0; i < orderItems.length; i++){
        const orderItem = orderItems[i];
        const itemAmount = orderItem.item_amount;
        const itemNumber = orderItem.item_number;
        const itemTotalAmount = itemAmount * itemNumber;
        totalAmount += itemTotalAmount;
      }
      return totalAmount;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async deleteOrder(orderId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Sales.deleteOrder(this.apiUrl, orderId, loginInfo);
        const newOrders = this.orders.filter(function(item) {
          return item.id !== orderId;
        });
        this.orders = newOrders;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.orders-list{
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
